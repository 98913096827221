import { cn } from '@/lib/utils'
import ResponsiveImage from '@/v2/components/helpers/responsive-image'
import { fadeIn } from '@/v2/constants/motion'
import { LinkType } from '@/v2/types'
import { CardType } from '@/v2/types/cards'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import React from 'react'

interface FourColGridProps {
  isBiggerImage?: boolean
  cards: (CardType & {
    links?: LinkType[]
  })[]
}
const FourColGrid: React.FC<FourColGridProps> = props => {
  const { cards, isBiggerImage = false } = props

  const mid = cards.length / 2

  return (
    <div className='grid mt-[30px] md-mn:mt-[48px] md:grid-cols-2 grid-cols-1 gap-[20px] xl:!grid-cols-4'>
      <LazyMotion features={domAnimation}>
        {cards.map((card, index) => {
          const description = card.description?.replaceAll(
            '###',
            `<a href='${card?.links?.[0]?.href || '#'}'>${card?.links?.[0]?.text}</a>`
          )

          return (
            <m.article
              key={card.id}
              className='text-center flex items-center flex-col lg-mn:p-[10px] p-[10px] lg-mn:pb-0 [&>h3]:text-heading-home
                [&>h3]:font-[600] [&>h3]:text-[20px] [&>h3]:mb-[12px] [&>h3]:mt-[20px]'
              variants={mid > index ? fadeIn('right', 'spring', 0, 1) : fadeIn('left', 'spring', 0, 1)}
            >
              {card.image && (
                <div>
                  <ResponsiveImage
                    src={card.image.src}
                    alt={card.image.alt}
                    className={cn('h-[200px]', isBiggerImage ? '!w-[352px]' : 'w-[200px]')}
                  />
                </div>
              )}
              <h3>{card.title}</h3>

              {description && (
                <p
                  className='text-paragraph leading-[24px] font-[400] text-[16px] [&>a]:text-[#407BFF]'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </m.article>
          )
        })}
      </LazyMotion>
    </div>
  )
}

export default FourColGrid
